<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                     style="min-height: 400px; background-size: cover; background-position: center top;">
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-7 col-md-10">
                        <h1 class="display-2 text-white">Hello {{user.firstname}}</h1>
                        <p class="text-white mt-0 mb-5">This is your profile page. You can see the progress you've made with your work and manage your projects or assigned tasks</p>                        
                    </div>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7" id="container" >
            <div class="row">
                <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                    <div class="card card-profile shadow">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="#">
                                        <img :src=model.avatar class="rounded-circle">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" id="card-header">
                            <div class="d-flex justify-content-between">
                            </div>
                        </div>
                        <div class="card-body pt-0 pt-md-4" id="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                        
                                        <div>
                                            <span class="heading">{{model.projects.length}}</span>
                                            <span class="description">Projects</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <h3>
                                    {{model.prefix}} {{model.firstname}}  {{model.lastname}}
                                </h3>
                                <div class="h5 font-weight-300">
                                    {{model.work_loc}}
                                </div>
                                <div class="h5 mt-4">
                                    <i class="ni business_briefcase-24 mr-2"></i>{{model.designation}}
                                </div>
                                <hr class="my-4" />
                                <p style="max-height : 500px; overflow-y : scroll; overflow-x : hidden" v-html="model.description"></p>
                                
                            </div>
                        </div>
                    </div>

                    <div class="card card-profile shadow mt-4">  
                        <div class="card-body pt-0 pt-md-4">                           
                            <div class="text-center">
                                    <h3>Change Password</h3>
                                    <hr class="my-4" />
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                        placeholder="Old Password"
                                                        input-classes="form-control-alternative"
                                                        v-model="password.old"
                                                        type = "password"
                                            />
                                        </div>                                        
                                    </div> 
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                        placeholder="New Password"
                                                        input-classes="form-control-alternative"
                                                        v-model="password.new"
                                                        type = "password"
                                            />
                                        </div>                                        
                                    </div> 
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                        placeholder="Confirm Password"
                                                        input-classes="form-control-alternative"
                                                        v-model="password.cnf"
                                                        type = "password"
                                            />
                                        </div>                                        
                                    </div> 
                                    <h6 class="ls-1 mb-1" style="font-style: italic; color : green" v-if="password.new == password.cnf && password.new != ''">Password matched!</h6>
                                    <h6 class="ls-1 mb-1" style="font-style: italic; color : red"   v-if="password.new != password.cnf && password.new != ''">Password do not match!</h6>
                                    <hr class="my-4" />  
                                    <base-button type="success" v-if="password.new == password.cnf && password.new != ''" @click="changePassword()">Change Password</base-button>   
                                    <base-button type="success" style="cursor : no-drop" disabled v-else>Change Password</base-button>                             
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-8 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">My account</h3>
                                </div>
                                <!-- <div class="col-4 text-right">
                                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                                </div> -->
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <h6 class="heading-small text-muted mb-4">User information</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Email Address"
                                                        placeholder="jesse@example.com"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.email"
                                                        disabled
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Work Email"
                                                        placeholder="jesse@work.com"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.work_email"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <base-input alternative=""
                                                        label="Prefix"
                                                        placeholder="Prefix"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.prefix"
                                                        
                                            />
                                        </div>
                                        <div class="col-lg-5">
                                            <base-input alternative=""
                                                        label="First name"
                                                        placeholder="First name"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.firstname"
                                                        disabled
                                            />
                                        </div>
                                        <div class="col-lg-5">
                                            <base-input alternative=""
                                                        label="Last name"
                                                        placeholder="Last name"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.lastname"
                                                        disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Address -->
                                <h6 class="heading-small text-muted mb-4">Work information</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <base-input alternative=""
                                                        label="Designation"
                                                        placeholder="Your Designation"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.designation"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Work Location"
                                                        placeholder="Your work location"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.work_loc"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">                                        
                                        <div class="col-lg-12">
                                            <div class="form-group" style="max-height : 300px; overflow-y : scroll; overflow-x : hidden">
                                                <base-input alternative=""
                                                            label="About Me">
                                                    <vue-editor v-model="model.description" :editorToolbar="customToolbar" ref="editor"></vue-editor>                                                    
                                                </base-input>                                                
                                            </div>
                                            <span v-html="wordlimit"></span>
                                            

                                        </div>                                        
                                    </div>
                                    <div class="row">                                        
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                label="Phone Number"
                                                placeholder="Your Phone Number"
                                                input-classes="form-control-alternative"
                                                v-model="model.phone"
                                            />
                                        </div>                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6" ref="croppieDiv">
                                            <div class="form-group has-label">
                                                <slot name="label">
                                                    <label class="form-control-label">
                                                        Profile Image
                                                    </label>
                                                </slot> 

                                                <ImageUpload :width=width v-if="width > 0"></ImageUpload>
                                                

                                                <!-- <input
                                                    type="file"
                                                    v-on:change="handleImageupload()"
                                                    class="form-control"
                                                    ref = "img"
                                                >                                                 -->
                                            </div>                                            
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group has-label">
                                                <slot name="label">
                                                    <label class="form-control-label">
                                                        Background Color
                                                    </label>
                                                </slot> 
                                                <!-- <color-picker v-bind="color" @input="onInput" :initially-collapsed="true"></color-picker> -->
                                                <chrome v-model="color" class="float-right"></chrome>
                                            </div>   
                                        </div>
                                    </div>  
                                </div>
                                <hr class="my-4" />
                                <h6 class="heading-small text-muted mb-4">Privacy Settings</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <base-checkbox class="" v-model="model._showProjects">
                                                Show Projects
                                            </base-checkbox>
                                        </div>  
                                    </div>
                                </div>
                                
                                <hr class="my-4" />
                                <base-button type="success" class="" v-on:click="onSubmit" >Update Profile</base-button>                                
                            </form>
                        </template>
                    </card>
                </div>
            </div>

            <div class="row mt-4 mb-4">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">                        
                        <template>
                            <form @submit.prevent>    
                                <!-- Description -->
                                <h6 class="heading-small text-muted mb-4">Projects</h6>
                                <div class="pl-lg-4">
                                    <div class="table-responsive">
                                        <base-table thead-classes="thead-light"
                                                    :data="model.projects">
                                            <template slot="columns">
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th scope="row" style="white-space : normal !important">
                                                    <span class="">{{row.title}}</span>
                                                </th>
                                                <td>
                                                    <badge class="badge-dot mr-4" :type="row.statusType">
                                                        <i :class="`bg-${row.statusType}`"></i>
                                                        <span class="status">{{row.status}}</span>
                                                    </badge>
                                                </td>  
                                                <td>
                                                    <base-button type="success" class="mt-3" v-on:click="updateProject(row._id)" v-if="row.status === 'Ongoing'" size="sm">Update</base-button>
                                                </td>                          
                                            </template>
                                        </base-table>
                                    </div>
                                </div>
                            </form>
                        </template>
                    </card>
                </div>
            </div>            
        </div>

            <modal :show.sync="welcome.view">
                <h6 slot="header" class="modal-title" id="modal-title-default">Welcome to <b>BH Lab</b> dashboard!</h6>

                <p>Dear <b>{{user.firstname}}</b>,</p>

                <p style="text-align : justify">Thank you for joining BH Lab.

                    <br><br>

                    Please help us update the website by filling in your details using this dashboard. You can also update the information anytime you want just by logging in to this portal again.

                    <br><br>

                    Log in & have fun !!
                
                </p>

                <template slot="footer">
                    <base-button type="primary" class="ml-auto" @click="start()">Get Started
                    </base-button>
                </template>
        </modal>


            <modal :show.sync="modal.view"
                gradient="danger"
                modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">There is error in data submission!</h4>
                    <p style="font-size : 14px">
                       <span v-for="formError in modal.errors" v-bind:key="'display-'+formError">
                            <b>{{formError}}</b><br>
                        </span> 
                    </p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="modal.view = false">Ok, Got it</base-button>                    
                </template>
            </modal>

        <modal :show.sync="update"
               body-classes="p-0"
               modal-classes="modal-dialog-centered">
            <card type="secondary" shadow
                  header-classes="bg-white pb-5"
                  body-classes="px-lg-5 py-lg-5 pb-custom"
                  class="border-0">
                <template>
                    <div class="text-center text-muted mb-4">
                        <small>Post a project update</small>
                    </div>                    

                    <form role="form">
                        <base-input alternative=""
                            label="Description">
                            <vue-editor v-model="timeline.description" :editorToolbar="customToolbar"></vue-editor>
                        </base-input>
                        
                        <div class="form-group">
                            <label class="form-control-label">Status</label>
                            <select class="form-control" id="admin_type" required v-model="timeline.status">
                                <option value="Ongoing">Ongoing</option>
                                <option value="Completed">Completed</option>
                            </select>
                        </div>
                        <div class="text-center">
                            <base-button type="primary" class="my-4" @click="postUpdate()">Post Update</base-button>
                        </div>
                    </form>

                </template>
            </card>
        </modal>

    </div>
</template>
<script>
  import axios from 'axios';
  import store from "../store";
  import ColorPicker from '@radial-color-picker/vue-color-picker';
  import { VueEditor } from "vue2-editor";
  import pickers from 'vue-color'
  export default {
    name: 'user-profile',
    components: { 
        ColorPicker,
        VueEditor,
        Chrome : pickers.Chrome, 
    },
    data() {
      return {
          colors : '#194d33',
        baseURL : store.state.baseURL, 
        width : 0, 
        user : store.state.user,
        password : {
            old : '',
            new : '',
            cnf : ''
        },
        model: {
          email: '',
          prefix : '',
          firstname: '',
          lastname: '',          
          designation : '',
          work_loc : '',
          work_email : '',
          description : '',
          phone : [],
          projects : [],
          timeline : [],
          _isFirst : '',
          img : '',
          avatar : '',
          color : '',
          _showProjects : ''          
        },
        welcome : {
            view : false
        },
        modal : {
            view : false,
            errors : []
        },
        images_flag : false,
        color: '',
        customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }],["link"],['clean']],
        update : false,
        timeline : {
            description : '',
            status : 'Ongoing',
            project_id : ''
        },
        wordlimit : '',
        maxWordLength : 200
      }
    },
    created(){            
        this.fetchDetails();         
    },
    mounted(){
        this.width = this.$refs.croppieDiv.clientWidth;
        
    },
    methods : {
        start() {
            this.welcome.view = false;
        },
        fetchDetails() {
            axios({
                url: this.baseURL + "admin",
                headers : {
                    authorization : store.state.token
                },
                method: "GET"
            })
            .then(res => {
                this.model = res.data.data;
                this.welcome.view = this.model._isFirst;
                if(!this.model.avatar || this.model.avatar == 'default.jpg')
                    this.model.avatar = "img/theme/default.png" 
                else
                    this.model.avatar = this.baseURL + this.model.avatar  

                if(this.model.color)
                {
                    this.color = this.model.color;
                }
                else
                    this.color.hue = '#ffffff';

                
            })
            .catch(err => {
                this.$notify({
                    group: 'notification',
                    title: 'Important message',
                    text: 'Error while fetching user data',
                    duration : 3000,
                    type : 'error'                        
                });
                
                console.log(err)
                if(err.response.status == 401)
                    this.logout();
                
            });
        },
        onSubmit(evt) {
            evt.preventDefault();
            let formData = new FormData();
            this.modal.errors = [];

            if(this.model.designation || this.model.designation == '')
            {
                formData.append('designation', this.model.designation)
            }                
            else
                this.modal.errors.push('Your designation cannot be empty!')
            
            

            formData.append('color', this.color.hex);
            formData.append('prefix', this.model.prefix);
            formData.append('work_email', this.model.work_email)

            if(this.model.work_loc || this.model.work_loc == '')
                formData.append('work_loc', this.model.work_loc)
            else
                this.modal.errors.push('Your work location cannot be empty!')

            if(this.model.description || this.model.description == '')
            {
                if((this.model.description.split(' ').length-1) > this.maxWordLength)
                {
                    this.modal.errors.push('Your description exceeds permisible word limit.')
                }
                else
                    formData.append('description', this.model.description)
            }
                
            else
                this.modal.errors.push('Your description cannot be empty!')

            if(!this.model.phone || this.model.phone == '')
                this.model.phone = '-'

            formData.append('phone', this.model.phone)  
            formData.append('_showProjects', this.model._showProjects)                    

            if(this.modal.errors.length > 0)
                this.modal.view = true
            else
            {                                
                axios({                    
                    url: this.baseURL + "admin",
                    headers : {
                        'Content-Type': 'multipart/form-data',
                        authorization : store.state.token
                    },
                    method: "PATCH",
                    data : formData
                })
                .then(response => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Successfully updated the data!',
                        duration : 3000,
                        type : 'success'                        
                    });
                    location.reload()
                })
                .catch(e => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Failed to upload data. Please check the data again!',
                        duration : 3000,
                        type : 'error'                        
                    });
                    console.log(e)
                })
            } 

        },
        handleImageupload(){
            this.model.img = this.$refs.img.files[0];
            this.images_flag = true;
        },
        logout() {
            axios({
            url : this.baseURL + "admin/logout",
            method : "GET",
            headers : {
                authorization : store.state.token
            }
            })
            localStorage.removeItem('token')
            store.commit('logoutUser')
            this.$router.push({ name: 'login' })
        },
        hslToHex(h, s, l) {
            h /= 360;
            s /= 100;
            l /= 100;
            let r, g, b;
            if (s === 0) {
                r = g = b = l; // achromatic
            } else {
                const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
                };
                const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
                const p = 2 * l - q;
                r = hue2rgb(p, q, h + 1 / 3);
                g = hue2rgb(p, q, h);
                b = hue2rgb(p, q, h - 1 / 3);
            }
            const toHex = x => {
                const hex = Math.round(x * 255).toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            };
            return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
        },
        hexToHSL(H) {
            // Convert hex to RGB first
            let r = 0, g = 0, b = 0;
            if (H.length == 4) {
                r = "0x" + H[1] + H[1];
                g = "0x" + H[2] + H[2];
                b = "0x" + H[3] + H[3];
            } else if (H.length == 7) {
                r = "0x" + H[1] + H[2];
                g = "0x" + H[3] + H[4];
                b = "0x" + H[5] + H[6];
            }
            // Then to HSL
            r /= 255;
            g /= 255;
            b /= 255;
            let cmin = Math.min(r,g,b),
                cmax = Math.max(r,g,b),
                delta = cmax - cmin,
                h = 0,
                s = 0,
                l = 0;

            if (delta == 0)
                h = 0;
            else if (cmax == r)
                h = ((g - b) / delta) % 6;
            else if (cmax == g)
                h = (b - r) / delta + 2;
            else
                h = (r - g) / delta + 4;

            h = Math.round(h * 60);

            if (h < 0)
                h += 360;

            l = (cmax + cmin) / 2;
            s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
            s = +(s * 100).toFixed(1);
            l = +(l * 100).toFixed(1);

            const hsl = {
                h : h,
                s : s,
                l : l
            }

            return hsl;
        },
        updateProject(id){
            this.timeline.description = '';
            this.timeline.status = 'Ongoing';
            this.timeline.project_id = id;
            this.update = true;
        },
        postUpdate(){
            if(this.timeline.description == '' && this.timeline.status == 'Completed')
                this.timeline.description = 'Project Completed!'
            
            if(this.timeline.description == '')
            {
                this.$notify({
                    group: 'notification',
                    title: 'Important message',
                    text: 'Description cannot be empty!',
                    duration : 5000,
                    type : 'error'                        
                });
            }
            else{
                axios({
                    url : this.baseURL + "project/update",
                    method : "POST",
                    headers : {
                        authorization : store.state.token
                    },
                    data : {
                        description : this.timeline.description,
                        status      : this.timeline.status,
                        project_id  : this.timeline.project_id
                    }
                })
                .then(response => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Successfully updated the data!',
                        duration : 3000,
                        type : 'success'                        
                    });
                    location.reload()
                })
                .catch(e => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Failed to post update!',
                        duration : 3000,
                        type : 'error'                        
                    });
                    console.log(e)
                })
            }           
        },
        changePassword(){
                axios({
                    url : this.baseURL + "admin/changePassword",
                    method : "POST",
                    headers : {
                        authorization : store.state.token
                    },
                    data : {
                        oldpassword : this.password.old,
                        newpassword : this.password.new
                    }
                })
                .then(response => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Successfully updated password!',
                        duration : 3000,
                        type : 'success'                        
                    });
                    this.password.old = '';
                    this.password.new = '';
                    this.password.cnf = '';

                })
                .catch(e => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text:  e.response.data.error + '! Could not update password',
                        duration : 3000,
                        type : 'error'                        
                    });
                    console.log(e.response.data.error)
                })

        }

    },
    watch: {
        'model.description' : function(newVal, oldVal){
            const length = newVal.split(' ').length - 1;
            if(length > this.maxWordLength)
            {
                this.wordlimit = '<h6 class="ls-1 mb-1" style="font-style: italic; color : red">'+length+'/'+this.maxWordLength+' words. Your content should not exceed the word limit!</h6>'
            }
            else
            {
                this.wordlimit = '<h6 class="ls-1 mb-1" style="font-style: italic; color : green">'+length+'/'+this.maxWordLength+' words</h6>'
            }
        },
        'color' : function(newVal, oldVal){
            document.getElementById('container').style.backgroundColor = newVal.hex;
            document.getElementById('card-body').style.backgroundColor = newVal.hex;
            document.getElementById('card-header').style.backgroundColor = newVal.hex;
            const rgbValue = [newVal.rgba.r, newVal.rgba.g, newVal.rgba.b];
            var color = Math.round(((parseInt(rgbValue[0]) * 299) + 
                (parseInt(rgbValue[1]) * 587) + 
                (parseInt(rgbValue[2]) * 114)) / 1000); 
            var textColor = (color > 125) ? 'black' : 'white'; 
            var backColor =  
                'rgb(' + rgbValue[0] + ', ' + rgbValue[1] + ', ' 
             + rgbValue[2] + ')'; 
              
            document.getElementById('card-body').style.color = textColor;
        }
    }
  };
</script>
<style>
    img {
        display: block;
        /* This rule is very important, please don't ignore this */
        max-width: 100%;  
    }

    .pb-custom {
        padding-bottom : 10px !important;
        padding-top : 25px !important; 
    }

</style>
