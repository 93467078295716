<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0" v-if="loader == false">                    
                    <div class="card-body px-lg-5 py-lg-5 ">                      
                        <form role="form">
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Email"
                                        addon-left-icon="ni ni-email-83"
                                        v-model="model.email">
                            </base-input>

                            <base-input class="input-group-alternative"
                                        placeholder="Password"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="model.password">
                            </base-input>

                            <div class="text-center">
                                <h5>{{message}}</h5>
                            </div>

                            <div class="text-center">
                                <base-button type="primary" class="" @click="login">Sign in</base-button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card shadow border-0" style="background:black" v-else>                    
                    <div class="card-body px-lg-5 py-lg-5 ">   
                        <img src="img/login.gif" style="width:50%; margin-left:auto; margin-right:auto; display:block;">                         
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <a href="#" class="text-light"><small>Forgot password?</small></a>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    import axios from 'axios';
    import store from '../store';
    export default {
        name: 'login',
        data() {
            return {
                baseURL : store.state.baseURL,
                model: {
                    email: '',
                    password: ''
                },
                message : '',
                loader : false
            }
        },
        methods : {
            login () {
                this.loader = true

                axios({
                    url : this.baseURL + "admin/login",
                    method : "POST",
                    data : this.model
                })
                .then(response => {
                    if(response.data.status)
                    {
                        setTimeout(() => {                             
                            localStorage.setItem("token", response.data.data.token);
                            localStorage.setItem("user", JSON.stringify(response.data.data));
                            store.commit('loginUser', {
                                token : response.data.data.token,
                                user  : response.data.data
                            });
                            this.$router.push({
                                name: "profile"
                            });

                        }, 3000);                        
                    }
                    else
                    {
                        setTimeout(() => {                             
                            this.loader = false;
                        }, 3000);      
                        this.message = response.data.message + ' - ' + response.data.error
                    }
                })
                .catch(err => {    
                    setTimeout(() => {                             
                        this.loader = false;
                    }, 1500);      
                    this.message = err.response.data.message + ' - ' + err.response.data.error     
                })
            }
        }
    }
</script>
<style>
</style>
