<template>
    <div>        
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">           
        </base-header>               

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Edit Project</h3>
                                </div>                               
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <h6 class="heading-small text-muted mb-4">Project information</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <card>
                                                <img :src=model.image style="max-height : 100%; max-width : 100%">        
                                            </card>
                                            
                                        </div>
                                        <div class="col-md-8">
                                            <div class="row">
                                                <div class="col-lg-12">                                            
                                                    <base-input alternative=""
                                                                label="Project Title"
                                                                placeholder="Project Title"
                                                                input-classes="form-control-alternative"
                                                                v-model="model.title"
                                                                :required='true'
                                                    />
                                                </div>                                        
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <base-input alternative=""
                                                                label="Subtitle"
                                                                placeholder="Project Subtitle"
                                                                input-classes="form-control-alternative"
                                                                v-model="model.subtitle"
                                                                :required='true'
                                                    />
                                                </div>
                                            <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Status</label>
                                                        <select class="form-control" id="admin_type" required v-model="model.status">
                                                            <option value="Ongoing">Ongoing</option>
                                                            <option value="Completed">Completed</option>
                                                        </select>
                                                    </div>
                                                </div>                               
                                            </div>                                                
                                        </div>
                                    </div>
                                    <div class="row">                                        
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <base-input alternative=""
                                                            label="Body">
                                                    <vue-editor v-model="model.body"></vue-editor>
                                                    <!-- <textarea rows="6" class="form-control form-control-alternative" placeholder="A few words about the project ..." v-model="model.body">A beautiful Dashboard for Bootstrap 4. It is Free and Open Source.</textarea> -->
                                                </base-input>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>                                 
                                <hr class="my-4" /> 
                                <h6 class="heading-small text-muted mb-4">Additional information</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Funding Agencies"
                                                        placeholder="Funding Agencies"
                                                        input-classes="form-control-alternative"
                                                        v-model="funds"
                                                        :required='true'
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-button size="sm" type="info" class="mt-4" @click="addFunds()">Add Agency</base-button>
                                            <h6 class="ls-1 mb-1" style="font-style: italic;">To add multiple agencies, insert with comma separation.</h6>
                                            <h6 class="ls-1 mb-1" style="font-style: italic; color : blue">Ex: IUCN, WWF, IFAW</h6>
                                        </div>                                 
                                    </div>  
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="ls-1 mb-1" v-if="model.fund.length > 0" style="font-style: italic; color : red">Click on grid number to delete it!</h6> 
                                            <base-button v-for="onegrid in model.fund" v-bind:key="'display-'+onegrid"  class="tagCss" @click="deleteTag(onegrid)">
                                                {{onegrid}}
                                            </base-button>
                                        </div>
                                    </div>  
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <br>
                                            <label class="form-control-label">Selected Researchers</label>
                                            <br>
                                            <base-button v-for="oneresearcher in selectedResearcher" v-bind:key="'display-'+oneresearcher._id"  class="tagCss" @click="deleteResearcher(oneresearcher)">
                                                {{oneresearcher.firstname}} {{oneresearcher.lastname}} 
                                            </base-button> 
                                        </div>
                                    </div> 
                                    <hr class="my-4" />
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="ls-1 mb-1" style="font-style: italic;">Available Researchers</h6>
                                            <base-button v-for="oneresearcher in researchers" v-bind:key="'display-'+oneresearcher._id"  class="tagCss bg-red small" @click="addResearcher(oneresearcher)">
                                                {{oneresearcher.firstname}} {{oneresearcher.lastname}} 
                                            </base-button>                                           
                                        </div>
                                    </div>  
                                </div>                              
                                <hr class="my-4" /> 
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Tags"
                                                        placeholder="Tags"
                                                        input-classes="form-control-alternative"
                                                        v-model="tags"
                                                        :required='true'
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-button size="sm" type="info" class="mt-4" @click="addTags()">Add Tag</base-button>
                                            <h6 class="ls-1 mb-1" style="font-style: italic;">To add multiple tags, insert with comma separation.</h6>
                                        </div>                                 
                                    </div> 
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="ls-1 mb-1" v-if="model.tags.length > 0" style="font-style: italic; color : red">Click on tag to delete it!</h6> 
                                            <base-button v-for="onetag in model.tags" v-bind:key="'display-'+onetag"  class="tagCss small" @click="deleteTags(onetag)">
                                                {{onetag}}
                                            </base-button>
                                        </div>
                                    </div>  
                                </div> 
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="Image-upload-wrapper Image-upload mt-5">
                                            <div id="croppie" ></div>
                                            <div id="upload-wrapper">
                                                <base-button type="success" class="" v-on:click="modalVisible=true" v-if="!modalVisible" size="sm"><i class="fa fa-camera"></i> Change Image</base-button>
                                                <div class="Image-modal" v-if="modalVisible">
                                                    <input
                                                        type="file"
                                                        v-on:change="setUpFileUploader"
                                                        class="form-control mt-3"
                                                        ref = "img"
                                                    >
                                                    <base-button type="success" class="mt-3" v-on:click="uploadFile" size="sm"><i class="fa fa-upload"></i> Upload</base-button>
                                                    <base-button type="warning" class="mt-3" v-on:click="resetFile" size="sm"><i class="fa fa-times"></i> Cancel</base-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <base-button type="danger" class="float-left" v-on:click="deleteProject()">Delete</base-button>
                                <base-button type="success" class="float-right" v-on:click="onSubmit">Update</base-button>                              
                            </form>
                        </template>
                    </card>                    
                </div>                
            </div>
        </div>

            <modal :show.sync="modal.view"
                gradient="danger"
                modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">There is error in data submission!</h4>
                    <p style="font-size : 14px">
                       <span v-for="formError in modal.errors" v-bind:key="'display-'+formError">
                            <b>{{formError}}</b> cannot be empty!<br>
                        </span> 
                    </p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="modal.view = false">Ok, Got it</base-button>                    
                </template>
            </modal>
    </div>
</template>
<script>
  import axios from 'axios';
  import Croppie from 'croppie';
  import store from '../store';
  import { VueEditor } from "vue2-editor";
  export default {
    name: 'user-profile',
    components: {
        VueEditor
    },
    props: ['id'],
    data() {
      return {
        baseURL : store.state.baseURL,
        projects : [],
        grid : '',
        model: {
            title : '',
            subtitle : '',
            body : '',
            fund : [],
            researchers : [],
            status : '',
            statusType : '',
            timeline : [],
            tags : [],
            image : ''
        },
        funds : '',
        researchers : [],
        tags : '',
        selectedResearcher : [],
        modal : {
            view : false,
            errors : []
        },
        modalVisible : false,
        image : null,
        croppie: null,
        uploadImage : null,
      }
    },
    methods : {
        onSubmit(evt){
            evt.preventDefault();
            let formData = new FormData();
            this.modal.errors = [];
            this.model.researchers = [];

            console.log(this.model);

            formData.append('id', this.id);

            if(this.model.title)
                formData.append('title', this.model.title)
            else
                this.modal.errors.push('Project Title')

            
            if(this.model.subtitle)
                formData.append('subtitle', this.model.subtitle)
            else
                this.modal.errors.push('Project Subtitle')


            if(this.model.status)
            {
                formData.append('status', this.model.status)
                if(this.model.status == 'Ongoing')
                    formData.append('statusType', 'danger')
                else
                    formData.append('statusType', 'success')
            }
            else
                this.modal.errors.push('Project Status')

            
            if(this.model.body)
                formData.append('body', this.model.body)
            else
                this.modal.errors.push('Project Body')

            
            if(this.model.fund.length <= 0)
                this.modal.errors.push('Funding Agencies')
            else
                formData.append('fund', this.model.fund)
            

            if(this.selectedResearcher.length <= 0)
                this.modal.errors.push('Researchers')
            else
            {
                for(var i = 0; i < this.selectedResearcher.length; i++)
                    this.model.researchers.push(this.selectedResearcher[i]._id);

                formData.append('researchers', this.model.researchers);
            }              


            if(this.model.tags.length <= 0)
                this.modal.errors.push('Tags')
            else
                formData.append('tags', this.model.tags);

            // this.croppie.result({
            //     type : 'canvas',
            //     size : 'viewport',
            //     type : 'base64',
            //     format : 'jpeg'
            // }).then(response => {
            //     var file = this.dataURLtoFile(response,'image.jpeg');
            //     this.model.image = file;
            //     formData.append('image', this.modal.image);          
                
                
            // })  

            
            if(this.uploadImage)
                formData.append('image', this.uploadImage); 

            console.log(formData);

            if(this.modal.errors.length > 0)
                    this.modal.view = true
            else
            {
                axios.patch(
                    this.baseURL + 'project',
                        
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            authorization : store.state.token
                        }
                    }
                )
                .then(response => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Successfully updated a project',
                        duration : 3000,
                            type : 'success'                        
                    });
                    this.resetFormFields()
                    location.reload()
                })
                .catch(e => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Failed to upload data. Please check the data again!',
                        duration : 3000,
                        type : 'error'                        
                    });
                    console.log(e)
                })
            }            
                          
        },
        addFunds(){
            var gridTemp = this.funds.split(", ");
            
            for(var x =  0; x < gridTemp.length; x++)
            {
                if(gridTemp[x] != '')
                {
                    var found = false;
                    for(var i = 0; i < this.model.fund.length; i++)
                    {
                        if(gridTemp[x] == this.model.fund[i])
                            found = true
                    }
                    if(!found)
                        this.model.fund.push(gridTemp[x]); 
                    
                    this.funds = ''; 
                }
            } 

        },        
        handleKMLupload(){
            this.model.kml = this.$refs.kml.files[0];
            this.model.filename = this.model.kml.name;
            this._kml = true;
        },
        addResearcher(tag){
            this.researchers = this.researchers.filter(oneresearcher=> oneresearcher !== tag);
            this.selectedResearcher.push(tag);
        }, 
        deleteResearcher(tag){
            this.selectedResearcher = this.selectedResearcher.filter(oneresearcher => oneresearcher !== tag);
            this.researchers.push(tag);
        },
        deleteTag(tag){
            this.model.fund = this.model.fund.filter(grid_number => grid_number !== tag);

        },
        deleteProject(){
            if(confirm("Do you want to delete this projec?"))
            {
                this.$notify({
                    group: 'notification',
                    title: 'Important message',
                    text: 'Successfully deleted the project',
                    duration : 3000,
                    type : 'success'                        
                });
            }
            else
            {
                this.$notify({
                    group: 'notification',
                    title: 'Important message',
                    text: 'Could not delete the project',
                    duration : 3000,
                    type : 'error'                        
                });
            }
        },
        addTags(){
            var tagTemp = this.tags.split(", ");
            
            for(var x =  0; x < tagTemp.length; x++)
            {
                if(tagTemp[x] != '')
                {
                    var found = false;
                    for(var i = 0; i < this.model.tags.length; i++)
                    {
                        if(tagTemp[x] == this.model.tags[i])
                            found = true
                    }
                    if(!found)
                        this.model.tags.push(tagTemp[x]); 
                    
                    this.tags = ''; 
                }
            } 
        },
        deleteTags(tag){
            this.model.tags = this.model.tags.filter(onetag => onetag !== tag);
        },
        resetFormFields(){
            
        },
            setUpCroppie() {
                try{
                    let el = document.getElementById('croppie');
                    this.croppie = new Croppie(el, {
                        viewport : { width : 320, height : 320, type : 'square' },
                        boundary : { width : 600, height : 320 },
                        showZoomer : true,
                        enableOrientation : true
                    });
                    this.croppie.bind({
                        url : this.image,
                    })
                }
                catch(err){
                    console.log('<<------- Croppie Error -------->>')
                    console.log(err);
                    console.log('<<------- XXXXXXXXXXXXX -------->>');
                }
            },
            setUpFileUploader(e){
                let files = e.target.files || e.dataTransfer.files;
                if(!files.length)
                    return
                else
                this.createImage(files[0]);
            },
            createImage(file){
                var image = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = (e) => {
                    vm.image = e.target.result;
                    vm.$emit('imageUploaded', e.target.result)
                }

                reader.readAsDataURL(file);
            },
            uploadFile(){
                this.croppie.result({
                    type : 'canvas',
                    size : 'viewport',
                    type : 'base64',
                    format : 'jpeg',
                    quality: 1
                }).then(response => {
                    var file = this.dataURLtoFile(response,'hello.jpeg');
                    this.uploadImage = file;
                    this.image = response;
                    this.modalVisible = false;                   
                })
            },
            resetFile(){
                this.modalVisible = false;
            },
            dataURLtoFile(dataurl, filename) { 
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), 
                    n = bstr.length, 
                    u8arr = new Uint8Array(n);
                    
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                
                return new File([u8arr], filename, {type:mime});
            }
    },
    created() {
        axios({
            url: this.baseURL + "project/" + this.id,
            headers : {
                authorization : store.state.token
            },
            method: "GET"
        })
        .then(res => {
            this.model = res.data.data;
            this.model.image = this.baseURL + this.model.image
            this.selectedResearcher = this.model.researchers;

            axios({
                url: this.baseURL + "allMobileAdmins",
                headers : {
                    authorization : store.state.token
                },
                method: "GET"
            })
            .then(res => {
                for( var i = 0 ; i < res.data.length ; i++ )
                {
                    var match = false;
                    for( var j = 0 ; j < this.selectedResearcher.length ; j++ )
                    {
                        if((res.data[i].firstname + res.data[i].lastname) == (this.selectedResearcher[j].firstname + this.selectedResearcher[j].lastname))
                        {
                            match = true;
                        }
                    }
                    if(!match)
                        this.researchers.push(res.data[i]);
                }
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));

        
    },
    mounted(){
            this.$on('imageUploaded', function(imageData){
                this.image = imageData;
                this.croppie.destroy();
                this.setUpCroppie(imageData);
            });
            this.image = 'img/theme/default.png';
            this.setUpCroppie();
        },
  };
</script>
<style scoped>
.tagCss{
  /* color: #000000; */
  padding: 5px 10px;
  /* border-radius: 5px; */
  /* margin: 4px 3px; */
  margin-top : 10px !important;
}
.bg-red {
    background-color: #ff0000 !important
}

.small {    
  font-size: 10px;
}
</style>

<style lang="scss">
    .Image-upload {
    .Image-modal{
        border-top : 1px solid #f4f4f4;
        margin-top : 10px;
        h4 {
            margin-bottom : 20px;
        }
    }

    div#upload-wrapper {
        text-align : center;
    }    
}
</style>
