<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 300px; background-size: cover; background-position: center top;"
    >
      <!-- Mask -->
      <span
        class="mask bg-gradient-success opacity-8"
        style="opacity : 0.6 !important"
      ></span>
      <!-- Header container -->
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-xl-12">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Add a User</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">User information</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Email address"
                        placeholder="youremail@website.com"
                        input-classes="form-control-alternative"
                        v-model="model.email"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Work Email"
                        placeholder="youremail@website.com"
                        input-classes="form-control-alternative"
                        v-model="model.work_email"
                      />
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label class="form-control-label">Type of Admin</label>
                        <select
                          class="form-control"
                          id="admin_type"
                          required
                          v-model="model.privilege"
                        >
                          <option value=""></option>
                          <option value="admin">Admin</option>
                          <option value="user">User</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <base-input
                        alternative=""
                        label="Prefix"
                        placeholder="Prefix"
                        input-classes="form-control-alternative"
                        v-model="model.prefix"
                      />
                    </div>
                    <div class="col-lg-5">
                      <base-input
                        alternative=""
                        label="First name"
                        placeholder="First name"
                        input-classes="form-control-alternative"
                        v-model="model.firstName"
                      />
                    </div>
                    <div class="col-lg-5">
                      <base-input
                        alternative=""
                        label="Last name"
                        placeholder="Last name"
                        input-classes="form-control-alternative"
                        v-model="model.lastName"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label"
                          >Category of User</label
                        >
                        <select
                          class="form-control"
                          id="category"
                          required
                          v-model="model.category"
                        >
                          <option value=""></option>
                          <option value="Supervisor">Supervisor</option>
                          <option value="Alumni">Alumni</option>
                          <option value="Current">Current</option>
                          <option value="International Collaborators"
                            >International Collaborators</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Designation"
                        placeholder="Designation"
                        input-classes="form-control-alternative"
                        v-model="model.designation"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <base-button
                  type="success"
                  class="float-right"
                  v-on:click="onSubmit"
                  >Submit</base-button
                >
              </form>
            </template>
          </card>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Admin List</h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                thead-classes="thead-light"
                :data="adminData"
                type="hover"
              >
                <template slot="columns">
                  <th>Email</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th></th>
                  <th colspan="2">Action</th>
                </template>

                <template slot-scope="{ row }">
                  <th scope="row">
                    {{ row.email }}
                  </th>
                  <td v-if="row.privilege == 'admin'">
                    <b>{{ row.firstname }} {{ row.lastname }}</b>
                  </td>
                  <td v-else>{{ row.firstname }} {{ row.lastname }}</td>
                  <td>
                    <!-- {{row.category}} -->
                    <select
                      class="form-control"
                      id="category"
                      required
                      v-model="row.category"
                      @change="updateCategory(row._id, row)"
                    >
                      <option value=""></option>
                      <option value="Supervisor">Supervisor</option>
                      <option value="Alumni">Alumni</option>
                      <option value="Current">Current</option>
                      <option value="International Collaborators"
                        >International Collaborators</option
                      >
                    </select>
                  </td>
                  <td>
                    <base-button
                      type="success"
                      size="sm"
                      @click="editUser(row._id)"
                      >Edit User</base-button
                    >
                  </td>
                  <td>
                    <base-button
                      type="warning"
                      size="sm"
                      @click="resetUserPassword(row._id)"
                      >Reset Password</base-button
                    >
                  </td>
                </template>
              </base-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      :show.sync="modal.view"
      gradient="danger"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <h6 slot="header" class="modal-title" id="modal-title-notification">
        Your attention is required
      </h6>

      <div class="py-3 text-center">
        <i class="ni ni-bell-55 ni-3x"></i>
        <h4 class="heading mt-4">There is error in data submission!</h4>
        <p style="font-size : 14px">
          <span
            v-for="formError in modal.errors"
            v-bind:key="'display-' + formError"
          >
            <b>{{ formError }}</b> cannot be empty!<br />
          </span>
        </p>
      </div>

      <template slot="footer">
        <base-button type="white" @click="modal.view = false"
          >Ok, Got it</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import store from "../store";
export default {
  name: "user-profile",
  data() {
    return {
      baseURL: store.state.baseURL,
      user: store.state.user,
      formData: {},
      adminData: [],
      model: {
        email: "",
        firstName: "",
        lastName: "",
        privilege: "",
        password: "",
        category: "",
        designation: "",
        work_email: "",
        prefix: "",
      },
      modal: {
        view: false,
        errors: [],
      },
      catModal: {
        view: false,
        firstname: "",
        lastname: "",
        category: "",
      },
    };
  },
  created() {
    axios({
      url: this.baseURL + "allMobileAdmins",
      headers: {
        authorization: store.state.token,
      },
      method: "GET",
    })
      .then((res) => {
        this.adminData = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    updateCategory(id, data) {
      console.log(data);
      axios({
        url: this.baseURL + "admin/edit_user",
        headers: {
          authorization: store.state.token,
        },
        method: "PATCH",
        data: {
          category: data.category,
          _id: id,
        },
      })
        .then((response) => {
          this.$notify({
            group: "notification",
            title: "Important message",
            text:
              "Category of " +
              data.firstname +
              " " +
              data.lastname +
              " has been changed!",
            duration: 3000,
            type: "success",
          });
        })
        .catch((e) => {
          this.$notify({
            group: "notification",
            title: "Important message",
            text: "Failed to change category. Please check the data again!",
            duration: 3000,
            type: "error",
          });
          console.log(e);
          location.reload();
        });
    },
    resetUserPassword(id) {
      if (confirm("Are you sure? Do you want to reset the passowed?")) {
        axios({
          url: this.baseURL + "admin/resetUserPassword",
          headers: {
            authorization: store.state.token,
          },
          method: "POST",
          data: {
            newpassword: "bhlab@2020",
            id: id,
          },
        })
          .then((res) => {
            this.$notify({
              group: "notification",
              title: "Important message",
              text: "Password for " + res.data.name + " changed!",
              duration: 1000,
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              group: "notification",
              title: "Important message",
              text: "Error in changing password!",
              duration: 1000,
              type: "error",
            });
          });
      }
    },
    generatePassword() {
      var length = 10,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$&",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    onSubmit() {
      this.modal.errors = [];

      if (this.model.email) this.formData.email = this.model.email;
      else this.modal.errors.push("Email cannot be empty!");

      if (this.model.firstName) this.formData.firstName = this.model.firstName;
      else this.modal.errors.push("First Name cannot be empty!");

      if (this.model.lastName) this.formData.lastName = this.model.lastName;
      else this.modal.errors.push("Last Name cannot be empty!");

      if (this.model.privilege) this.formData.privilege = this.model.privilege;
      else this.modal.errors.push("Privilege cannot be empty!");

      if (this.model.category) this.formData.category = this.model.category;
      else this.modal.errors.push("Category cannot be empty!");

      if (this.formData.category == "International Collaborators")
        this.formData.collaborator = true;
      else this.formData.collaborator = false;

      this.formData.password = this.generatePassword();
      this.formData.designation = this.model.designation;
      this.formData.work_email = this.model.work_email;
      this.formData.prefix = this.model.prefix;

      if (this.modal.errors.length > 0) this.modal.view = true;
      else {
        axios({
          url: this.baseURL + "admin/register",
          headers: {
            authorization: store.state.token,
          },
          method: "POST",
          data: {
            email: this.formData.email,
            firstName: this.formData.firstName,
            lastName: this.formData.lastName,
            password: this.formData.password,
            privilege: this.formData.privilege,
            designation: this.formData.designation,
            category: this.formData.category,
            work_email: this.formData.work_email,
            prefix: this.formData.prefix,
          },
        })
          .then((response) => {
            this.$notify({
              group: "notification",
              title: "Important message",
              text: "Admin Registered",
              duration: 1000,
              type: "success",
            });
            location.reload();
            this.resetFormFields();
          })
          .catch((e) => {
            this.$notify({
              group: "notification",
              title: "Important message",
              text: e,
              duration: 2000,
              type: "error",
            });
            console.log(e);
          });
      }
    },
    revokeUser() {
      this.$notify({
        group: "notification",
        title: "Important message",
        text: "Revoke function is not yet activated!",
        duration: 2000,
        type: "error",
      });
    },
    editUser(id) {
      this.$router.push({ name: "edit user", params: { id: id } });
    },
    resetFormFields() {},
  },
};
</script>
<style></style>
