<template>
    <div>        
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">           
        </base-header>               

        <div class="container-fluid mt--7">
            <div class="row" v-if="loading == false">
                <div class="col-xl-9">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Add a Publication</h3>
                                </div>                               
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <h6 class="heading-small text-muted ">Publication information</h6>
                                <h6 class="ls-1 mb-4" style="font-style: italic;" v-if="!model.citationResult">Please add publications one by one</h6>
                                <h6 class="ls-1 mb-4" style="font-style: italic;" v-else>Please verify details of your publication. Change them manually if you find a mistake.</h6>
                                <div class="pl-lg-4">
                                    <div class="row" v-if="!model.citationResult">
                                        <div class="col-lg-12">                                            
                                            <div class="form-group">
                                                <textarea rows="6" class="form-control form-control-alternative" placeholder="Place your BibTex here" v-model="citation"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <base-button size="sm" type="warning" class="mt-4 float-left" @click="manualAdd()">I don't have BibTex</base-button>
                                            <base-button size="sm" type="info" class="mt-4 float-right" @click="generateCitation(citation)">Convert</base-button>
                                        </div>                                     
                                    </div>                                    
                                    <hr class="my-4" v-if="model.citationResult"/>
                                    <div class="row" v-if="model.citationResult">
                                        <div class="col-md-12">
                                            <base-input alternative=""
                                                        label="Title"
                                                        placeholder="Tiltle of the publication"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.citationResult.title"
                                            />
                                        </div>
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                        label="Authors"
                                                        placeholder="Authors of publication"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.citationResult.author"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Year"
                                                        placeholder="Year of publication"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.citationResult.year"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Type of Publication"
                                                        placeholder="Type of publication"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.citationResult.type"
                                            />
                                        </div>
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                        label="URL"
                                                        placeholder="URL of publication"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.citationResult.url"
                                            />
                                        </div>
                                    </div>
                                    <div class="row" v-if="model.citationResult">
                                        <div class="col-lg-12">                                            
                                            <base-button size="sm"  type="danger" class="mt-4 float-right" @click="clearCitation()">Clear</base-button>
                                        </div>                                       
                                    </div>                                    
                                </div>                                 
                                <hr class="my-4" /> 
                                <h6 class="heading-small text-muted mb-4">Additional information</h6>
                                <div class="pl-lg-4">                                    
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label class="form-control-label">Selected Researchers</label>
                                            <br>
                                            <base-button v-for="oneresearcher in selectedResearcher" v-bind:key="'display-'+oneresearcher._id"  class="tagCss" @click="deleteResearcher(oneresearcher)">
                                                {{oneresearcher.firstname}} {{oneresearcher.lastname}} 
                                            </base-button> 
                                        </div>
                                    </div> 
                                    <hr class="my-4" />
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="ls-1 mb-1" style="font-style: italic;">Available Researchers</h6>
                                            <base-button v-for="oneresearcher in researchers" v-bind:key="'display-'+oneresearcher._id"  class="tagCss bg-red small" @click="addResearcher(oneresearcher)">
                                                {{oneresearcher.firstname}} {{oneresearcher.lastname}} 
                                            </base-button>                                           
                                        </div>
                                    </div>  
                                </div>                              
                                <hr class="my-4" /> 
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Tags"
                                                        placeholder="Tags"
                                                        input-classes="form-control-alternative"
                                                        v-model="tags"
                                                        :required='true'
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-button size="sm" type="info" class="mt-4" @click="addTags()">Add Tag</base-button>
                                            <h6 class="ls-1 mb-1" style="font-style: italic;">To add multiple tags, insert with comma separation.</h6>
                                        </div>                                 
                                    </div> 
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 class="ls-1 mb-1" v-if="model.tags.length > 0" style="font-style: italic; color : red">Click on tag to delete it!</h6> 
                                            <base-button v-for="onetag in model.tags" v-bind:key="'display-'+onetag"  class="tagCss small" @click="deleteTags(onetag)">
                                                {{onetag}}
                                            </base-button>
                                        </div>
                                    </div>  
                                </div>                                 
                                <hr class="my-4" />
                                <base-button type="success" class="float-right" v-on:click="onSubmit">Submit</base-button>                              
                            </form>
                        </template>
                    </card>                    
                </div>
                <div class="col-xl-3">
                    <card header-classes="bg-transparent" style="height:800px; overflow:auto">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h5 class="h3 mb-0">Your Publications</h5>
                                <h6 class="text-muted ls-1 mb-1" style="font-style: italic;" v-if="publications.length < 1">No publications available in database</h6>
                                <h6 class="text-muted ls-1 mb-1" v-else>{{publications.length}} Publications available</h6>                                
                            </div>
                        </div>           
                                        <base-table thead-classes="thead-light"
                                                    :data="publications"
                                                    type="hover">
                                            <template slot-scope="{row}">
                                                <th scope="row" style="white-space : normal !important">
                                                    <span class="">{{row.title}}</span>
                                                </th>
                                                <td>
                                                    <base-button type="danger" class="mt-3" v-on:click="deletePublication(row._id)" size="sm">Delete</base-button>
                                                </td>                          
                                            </template>
                                        </base-table>  
                        <!-- <span v-for="project in publications" v-bind:key="'display-'+project._id">
                            <h6 class="text-muted ls-1 mb-1 greyHover" style="cursor : pointer" v-html="project.title"></h6>
                             <base-button size="sm" type="info" class="mt-4" @click="addTags()">Add Tag</base-button>
                        </span>                         -->
                    </card>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-xl-12" style="height : 500px">
                    <card shadow type="secondary">
                        <base-progress type="default" :height="30" :value=loadPublication label="Uploading Publications" :striped=true :animated=true></base-progress>
                        <h6 class="ls-1 mb-1" style="font-style: italic; text-align : center; color:red">Do not refresh or press back while uploading data</h6>
                    </card>
                </div>
            </div>
        </div>

            <modal :show.sync="modal.view"
                gradient="danger"
                modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">There is error in data submission!</h4>
                    <p style="font-size : 14px">
                       <span v-for="formError in modal.errors" v-bind:key="'display-'+formError">
                            <b>{{formError}}</b> cannot be empty!<br>
                        </span> 
                    </p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="modal.view = false">Ok, Got it</base-button>                    
                </template>
            </modal>
    </div>
</template>
<script>
  import axios from 'axios';
  import Croppie from 'croppie';
  import store from '../store';
  import { VueEditor } from "vue2-editor";
  import Cite from 'citation-js';
  export default {
    name: 'user-profile',
    components: {
        VueEditor
    },
    data() {
      return {
        baseURL : store.state.baseURL,
        user    : store.state.user,
        projects : [],
        grid : '',
        model: {
            citationResult : null,
            researchers : [],            
            tags : [],
        },
        researchers : [],
        tags : '',
        selectedResearcher : [],
        modal : {
            view : false,
            errors : []
        },
        modalVisible : false,
        citation : '',        
        loading : false,
        loadPublication : 5,
        publications : [],
        timer : null
      }
    },
    methods : {
        deletePublication(id){
            axios({
                url: this.baseURL + "publication",
                headers : {
                    authorization : store.state.token
                },
                method: "DELETE",
                data : {
                    id : id
                }
            })
            .then(res => {     
                this.publications = this.publications.filter(onepublication=> onepublication._id !== id);
                location.reload();
            })
            .catch(err => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Failed to delete Publication. Please try again!',
                        duration : 3000,
                        type : 'error'                        
                    });
                    console.log(err)
                }        
            );          
        },
        generateCitation(str) {
            var allCites = str.split("@");

            if(allCites.length <= 1)
            {
                this.$notify({
                    group: 'notification',
                    title: 'Important message',
                    text: 'Error with the BibText you addedd!',
                    duration : 3000,
                    type : 'warning'                        
                });
            }

                try{
                    var cite = new Cite('@'+allCites[1]);

                    var citeTemp = {
                        publication : '',
                        title   : '',
                        type    : '',
                        year    : ''
                    }

                    citeTemp.publication = cite.format('bibliography', {
                            format: 'html',
                            template: 'apa',
                            lang: 'en-US'
                        });
                    var convert = JSON.parse(cite.format('data'));

                    convert = convert[0];
                    console.log(convert);
                    citeTemp.author = '';
                    for(var i = 0; i < convert.author.length; i++)
                    {
                        if(convert.author[i].family && convert.author[i].given)
                            citeTemp.author += convert.author[i].given + ' ' + convert.author[i].family + ', ';

                        else if(convert.author[i].family)
                            citeTemp.author += convert.author[i].family + ', ';

                        else if(convert.author[i].given)
                            citeTemp.author += convert.author[i].given + ', ';

                        else if(convert.author[i].literal)
                            citeTemp.author += convert.author[i].literal + ', ';
                    }

                    if(convert.DOI)
                    {
                        citeTemp.url = 'http://dx.doi.org/'+convert.DOI;
                    }
                    else if(convert.URL)
                    {
                        citeTemp.URL = convert.URL.split(" ");
                        citeTemp.url = citeTemp.URL[0];
                    }
                    else
                        citeTemp.url = null;




                    citeTemp.author = citeTemp.author.slice(0, -2); ;
                    citeTemp.title  = convert.title;
                    citeTemp.type   = convert.type;
                    citeTemp.year   = convert.issued["date-parts"][0][0];

                    
                    console.log(citeTemp)
                    this.model.citationResult = citeTemp
                }
                catch(e)
                {
                    console.log('ERROR IN  of CITATION');
                    console.log(e);
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Error with the BibText you addedd!',
                        duration : 3000,
                        type : 'error'                        
                    });
                    
                }                     
        },
        loader(){
            this.loading = true

            var self = this;

            this.timer = setInterval(function(){
                self.loadPublication += Math.floor(Math.random() * 12) + 1;
                if(self.loadPublication >= 85)
                {
                    self.clear();
                }
                    
            }, 300);
        },
        clear(){
            clearInterval(this.timer);
        },
        clearCitation(){
            this.citation = '';
            this.model.citationResult = null;
        },
        manualAdd(){
            this.model.citationResult = {
                title   : '',
                author  : '',
                year    : '',
                type    : '',
                url     : '',
            }
        },
        onSubmit(evt){
            evt.preventDefault();
            var formData = {};
            this.modal.errors = [];
            this.model.researchers = [];                            
            

            if(this.selectedResearcher.length <= 0)
                this.modal.errors.push('Researchers')
            else
            {
                for(var i = 0; i < this.selectedResearcher.length; i++)
                    this.model.researchers.push(this.selectedResearcher[i]._id);

                formData.researchers = this.model.researchers;
            }


            
            formData.tags = this.model.tags;


            if(this.model.citationResult)
            {
                if(this.model.citationResult.title != '')
                    formData.title  = this.model.citationResult.title
                else
                    this.modal.errors.push('Publication Title');
                
                if(this.model.citationResult.author != '')
                    formData.author  = this.model.citationResult.author
                else
                    this.modal.errors.push('Publication Author');

                if(this.model.citationResult.year != '')
                    formData.year  = this.model.citationResult.year
                else
                    this.modal.errors.push('Publication Year');

                if(this.model.citationResult.type != '')
                    formData.type  = this.model.citationResult.type
                else
                    this.modal.errors.push('Publication Type');

                formData.url  = this.model.citationResult.url                    
            }
            else
                this.modal.errors.push('Publications')

            

            if(this.modal.errors.length > 0)
                this.modal.view = true
            else
            {
                this.loader();
                axios.post(
                    this.baseURL + 'publication',
                        
                    formData,
                    {
                        headers: {
                            authorization : store.state.token
                        }
                    }
                )
                .then(response => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Successfully added publication',
                        duration : 3000,
                        type : 'success'                        
                    });
                    this.resetFormFields()
                    location.reload()
                })
                .catch(e => {
                    this.$notify({
                        group: 'notification',
                        title: 'Important message',
                        text: 'Failed to upload data. Please check the data again!',
                        duration : 3000,
                        type : 'error'                        
                    });
                    this.loading = false;
                    this.loadPublication = 5;
                    console.log(e)
                })
            }               
        },     
        addResearcher(tag){
            this.researchers = this.researchers.filter(oneresearcher=> oneresearcher !== tag);
            this.selectedResearcher.push(tag);
        }, 
        deleteResearcher(tag){
            this.selectedResearcher = this.selectedResearcher.filter(oneresearcher => oneresearcher !== tag);
            this.researchers.push(tag);
        },        
        addTags(){
            var tagTemp = this.tags.split(", ");
            
            for(var x =  0; x < tagTemp.length; x++)
            {
                if(tagTemp[x] != '')
                {
                    var found = false;
                    for(var i = 0; i < this.model.tags.length; i++)
                    {
                        if(tagTemp[x] == this.model.tags[i])
                            found = true
                    }
                    if(!found)
                        this.model.tags.push(tagTemp[x]); 
                    
                    this.tags = ''; 
                }
            } 
        },
        deleteTags(tag){
            this.model.tags = this.model.tags.filter(onetag => onetag !== tag);
        },
        resetFormFields(){
            
        },          
    },
    created() {
        axios({
            url: this.baseURL + "allMobileAdmins",
            headers : {
                authorization : store.state.token
            },
            method: "GET"
        })
        .then(res => {
            this.researchers = res.data
            
            this.selectedResearcher.push(this.researchers.find(oneresearcher=> oneresearcher._id == this.user._id));
            this.researchers = this.researchers.filter(oneresearcher=> oneresearcher._id !== this.user._id);
            console.log(this.user)
        })
        .catch(err => {
                this.$notify({
                    group: 'notification',
                    title: 'Important message',
                    text: 'Failed to fetch Researchers list. Please refresh page',
                    duration : 3000,
                    type : 'error'                        
                });
                console.log(err)
            }        
        );


        axios({
            url: this.baseURL + "publication",
            headers : {
                authorization : store.state.token
            },
            method: "GET"
        })
        .then(res => this.publications = res.data.publication)
        .catch(err => {
                this.$notify({
                    group: 'notification',
                    title: 'Important message',
                    text: 'Failed to fetch Publications list.',
                    duration : 3000,
                    type : 'error'                        
                });
                console.log(err)
            }        
        );
    },
    mounted(){
            
        },
  };
</script>
<style scoped>
.tagCss{
  /* color: #000000; */
  padding: 5px 10px;
  /* border-radius: 5px; */
  /* margin: 4px 3px; */
  margin-top : 10px !important;
}
.bg-red {
    background-color: #ff0000 !important
}

.greyHover {
    margin-bottom : 15px !important;
    padding : 5px
}

.greyHover:hover {
    background-color : #f0f0f0
}

.small {    
  font-size: 10px;
}
</style>

<style lang="scss">
    .Image-upload {
    .Image-modal{
        border-top : 1px solid #f4f4f4;
        margin-top : 10px;
        h4 {
            margin-bottom : 20px;
        }
    }    

    div#upload-wrapper {
        text-align : center;
    }    
}
</style>
