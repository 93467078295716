<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Corridors"
                                type="gradient-red"
                                :sub-title=corridors.length.toString()
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-nowrap">No. of corridors in database</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Users"
                                type="gradient-orange"
                                :sub-title=users.length.toString()
                                icon="fa fa-users"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-nowrap">No. of users in database</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Threats"
                                type="gradient-green"
                                :sub-title=threats.length.toString()
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-nowrap">No. of threats in database</span>
                            <!-- <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
                            <span class="text-nowrap">Since last month</span> -->
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Animal"
                                type="gradient-info"
                                :sub-title=(direct.length+indirect.length).toString()
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-nowrap">No. of animal sightings</span>
                            <!-- <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
                            <span class="text-nowrap">Since last month</span> -->
                        </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-9 mb-5 mb-xl-0">
                    <card type="default" header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h5 class="h3 text-white mb-0">Overview</h5>
                                <h6 class="text-light ls-1">Shows visualisation of data over the map</h6>                                
                            </div>
                            <!-- <div class="col">
                                <ul class="nav nav-pills justify-content-end">
                                    <li class="nav-item mr-2 mr-md-0">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: bigLineChart.activeIndex === 0}"
                                           @click.prevent="initBigChart(0)">
                                            <span class="d-none d-md-block">Month</span>
                                            <span class="d-md-none">M</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: bigLineChart.activeIndex === 1}"
                                           @click.prevent="initBigChart(1)">
                                            <span class="d-none d-md-block">Week</span>
                                            <span class="d-md-none">W</span>
                                        </a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <div id="map-canvas" class="map-canvas" data-lat="20.5937" data-lng="78.9629" data-zoom="4" style="height: 375px;"></div>

                    </card>
                </div>

                <div class="col-xl-3">
                    <card header-classes="bg-transparent" style="max-height:510px; overflow:auto">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h5 class="h3 mb-0">Data Filters</h5>
                                <h6 class="text-muted ls-1 mb-1">Select options to see changes in map</h6>
                            </div>                            
                        </div>
                        <div slot="header" class="row align-items-center" v-if="loaders < 100">
                            <div class="col">
                                <div class="progress" style="height: 5px">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-default"
                                        role="progressbar"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        :style="`width: ${loaders}%;`">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <base-checkbox class="mb-3" v-model="filters.corridor">
                            Corridors 
                        </base-checkbox>
                        <base-checkbox class="mb-3" v-model="filters.users">
                            User Distribution
                        </base-checkbox>
                        <base-checkbox class="mb-3" v-model="filters.threats">
                            Threats
                        </base-checkbox>
                        <base-checkbox class="mb-3" v-model="filters.direct">
                            Direct Sightings
                        </base-checkbox>
                        <base-checkbox class="mb-3" v-model="filters.indirect">
                            Indirect Observations
                        </base-checkbox>
                    </card>
                </div>
            </div>
            <!-- End charts-->

            <!--Tables-->
            <!-- <div class="row mt-5">
                <div class="col-xl-8 mb-5 mb-xl-0">
                    <page-visits-table></page-visits-table>
                </div>
                <div class="col-xl-4">
                    <social-traffic-table></social-traffic-table>
                </div>
            </div> -->
            <!--End tables-->
        </div>
    </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Tables
  import SocialTrafficTable from './Dashboard/SocialTrafficTable';
  import PageVisitsTable from './Dashboard/PageVisitsTable';

  import axios from 'axios'
  import store from '../store';

  export default {
    components: {
      LineChart,
      BarChart,
      PageVisitsTable,
      SocialTrafficTable,
    },
    data() {
      return {
        baseURL : store.state.baseURL,
        filters: {
            corridor: true,
            users   : true,
            threats : false,
            direct  : false,
            indirect: false,
        },
        markers : {
            users   : [],
            threats : [],
            direct  : [],
            indirect: [],
        },
        map : '',
        geocoder : '',
        corridorMaster : '',
        markerCluster : '',

        corridors   : [],
        users       : [],
        threats     : [],
        direct      : [],
        indirect    : [],

        loaders : 0
      };
    },
    methods: {
        toggleCorridor(){
            if(!this.filters.corridor)
                this.corridorMaster.setMap(null); 
            else
                this.corridorMaster.setMap(this.map);                       
        },  
        
        toggleUsers(){

            if(!this.filters.users)
            {
                for(var i = 0; i < this.markers.users.length; i++)
                {
                    this.markers.users[i].setVisible(false);
                }
                this.markerCluster.clearMarkers();
            }                 
            else
            {
                for(var i = 0; i < this.markers.users.length; i++)
                {
                    this.markers.users[i].setVisible(true);
                }
                this.markerCluster.addMarkers(this.markers.users);
            }           
                  
        },
        
        toggleThreats(){

            if(!this.filters.threats)
            {
                for(var i = 0; i < this.markers.threats.length; i++)
                {
                    this.markers.threats[i].setVisible(false);
                }
                // this.markerCluster.clearMarkers();
            }                 
            else
            {
                for(var i = 0; i < this.markers.threats.length; i++)
                {
                    this.threats.users[i].setVisible(true);
                }
                // this.markerCluster.addMarkers(this.markers.users);
            }           
                  
        },
        
        
        toggleDirect(){

            if(!this.filters.direct)
            {
                for(var i = 0; i < this.markers.direct.length; i++)
                {
                    this.markers.direct[i].setVisible(false);
                }
                // this.markerCluster.clearMarkers();
            }                 
            else
            {
                for(var i = 0; i < this.markers.direct.length; i++)
                {
                    this.markers.direct[i].setVisible(true);
                }
                // this.markerCluster.addMarkers(this.markers.users);
            }           
                  
        },
        
        
        toggleIndirect(){

            if(!this.filters.indirect)
            {
                for(var i = 0; i < this.markers.indirect.length; i++)
                {
                    this.markers.indirect[i].setVisible(false);
                }
                // this.markerCluster.clearMarkers();
            }                 
            else
            {
                for(var i = 0; i < this.markers.indirect.length; i++)
                {
                    this.markers.indirect[i].setVisible(true);
                }
                // this.markerCluster.addMarkers(this.markers.users);
            }           
                  
        }
    },
    mounted() {
      let google= window.google
      let map = document.getElementById('map-canvas');
      let lat = map.getAttribute('data-lat');
      let lng = map.getAttribute('data-lng');
      let zoom= parseInt(map.getAttribute('data-zoom'));

      const myLatlng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
        zoom: zoom,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.HYBRID,        
      }      

      this.map = new google.maps.Map(map, mapOptions);

      this.corridorMaster = new google.maps.KmlLayer({
            url: 'http://gcc.serverwildlifeconflict.in/uploads/masterkmz.kmz',
            map: this.map
      });
      console.log(this.corridorMaster)

      if(!this.filters.corridor)
        this.corridorMaster.setMap(null);          
    },
    created() {
        axios({
            url : this.baseURL + "adminLoggedIn",
            headers : {
                authorization : store.state.token
            },
            method : "GET"
        })
        .then(res => {
            if(!res.status)
            {
                localStorage.removeItem('token')
                store.commit('logoutUser')
                this.$router.push({ name: 'login' })
            }
        })
        .catch(err => {
            localStorage.removeItem('token')
            store.commit('logoutUser')
            this.$router.push({ name: 'login' })
        });

        axios({
            url: this.baseURL + "getAllCorridors",
            headers : {
                authorization : store.state.token
            },
            method: "GET"
        })
        .then(res => {
            this.corridors = res.data.data;
            this.loaders += 20;            
        })
        .catch(err => {
            this.$notify({
                group: 'notification',
                title: 'Important message',
                text: 'Error while fetching corridor data',
                duration : 3000,
                type : 'error'                        
            });
            console.log(err)
        });

        axios({
            url: this.baseURL + "allMobileUsers",
            headers : {
                authorization : store.state.token
            },
            method: "GET"
        })
        .then(res => {
            this.users = res.data; 
            
            this.geocoder = new google.maps.Geocoder();
            for (var i = 0; i < this.users.length; i++) 
            {                      
                var latLng = new google.maps.LatLng(this.users[i].location.coordinates[1],this.users[i].location.coordinates[0]);
                var marker = new google.maps.Marker({
                    position: latLng,
                    icon : 'http://leopardtechlabs.com/fred.png',
                    map : this.map
                });
                this.markers.users.push(marker);
            }
            
            this.markerCluster = new MarkerClusterer(this.map, this.markers.users, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', ignoreHidden: true});
            this.toggleUsers();
            this.loaders += 20;
        })
        .catch(err => {
            this.$notify({
                group: 'notification',
                title: 'Important message',
                text: 'Error while fetching user data',
                duration : 3000,
                type : 'error'                        
            });
            console.log(err)
        });

        axios({
            url: this.baseURL + "getAllRecords",
            headers : {
                authorization : store.state.token
            },
            method: "GET"
        })
        .then(res => {
            this.threats = res.data.threats; 
            this.direct  = res.data.direct;
            this.indirect= res.data.indirect;

            for(var key1 = 0; key1 < this.threats.length; key1++)
            {
                var latLng = new google.maps.LatLng(this.threats[key1].location.coordinates[1],this.threats[key1].location.coordinates[0]);
                var marker = new google.maps.Marker({
                    position: latLng,
                    icon : 'http://leopardtechlabs.com/fred.png',
                    map : this.map
                });
                this.markers.threats.push(marker);
            }
            this.toggleThreats();

            for(var key2 = 0; key2 < this.direct.length; key2++)
            {
                var latLng = new google.maps.LatLng(this.direct[key2].location.coordinates[1],this.direct[key2].location.coordinates[0]);
                var marker = new google.maps.Marker({
                    position: latLng,
                    icon : 'http://leopardtechlabs.com/fred.png',
                    map : this.map
                });
                this.markers.direct.push(marker);
            }
            this.toggleDirect();

            for(var key3 = 0; key3 < this.indirect.length; key3++)
            {
                var latLng = new google.maps.LatLng(this.indirect[key3].location.coordinates[1],this.indirect[key3].location.coordinates[0]);
                var marker = new google.maps.Marker({
                    position: latLng,
                    icon : 'http://leopardtechlabs.com/fred.png',
                    map : this.map
                });
                this.markers.indirect.push(marker);
            }     
            this.toggleIndirect();      
            
            
            this.loaders += 60;
        })
        .catch(err => {
            this.$notify({
                group: 'notification',
                title: 'Important message',
                text: 'Error while fetching other data',
                duration : 3000,
                type : 'error'                        
            });
            console.log(err)
        });
    

    },
    watch: {
        'filters.corridor' : function(newVal, oldVal){
            this.toggleCorridor();            
        },
        'filters.users' : function(newVal, oldVal){
            this.toggleUsers();            
        },
        'filters.threats' : function(newVal, oldVal){
            this.toggleThreats();            
        },
        'filters.direct' : function(newVal, oldVal){
            this.toggleDirect();            
        },
        'filters.indirect' : function(newVal, oldVal){
            this.toggleIndirect();            
        },
    },
  };
</script>
<style></style>
